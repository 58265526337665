import React from 'react'
import { graphql, Link } from 'gatsby'
import Meta from '../components/meta'
import Layout from '../components/layout'
import Sidebar from '../components/sidebar'
import Breadcrumb from '../components/breadcrumb'
import styles from './author.module.css'

export const pageQuery = graphql`
  query($nickName: String!) {
    site {
      siteMetadata {
        title
        description
        host
        author
        tagline
      }
    }
    contentfulPerson(nickName: {eq: $nickName}) {
      github
      facebook
      twitter
      name
      nickName
      job
      shortBio {
        childMarkdownRemark {
          html
        }
      }
      image {
        file {
          url
        }
      }
    }
  }
`

const Author = ({ data, pageContext, location }) => {
  const site = data.site
  const author = data.contentfulPerson
  const breadcrumbList = [{str: 'トップ', path: '/'}, {str: 'メンバー'}]

  return (
    <Layout location={location}>
      <div style={{ background: '#fff' }}>
        <Meta
          site={site}
        />
        <div className="wrapper">
          <div className="articlesContainer">
            <div>
              <Breadcrumb breadcrumbList={breadcrumbList}/>
              <div className={styles.profileWrap}>
                <div className={styles.thumbNailWrap} style={{backgroundImage: `url(${author.image.file.url})`}} >
                </div>
                <div className={styles.profile}>
                  <h1 className={styles.name}>{author.name}</h1>
                  <p className={styles.job}>{author.job}</p>
                  <div className={styles.socialLinks}>
                    <Link to={author.github} className={styles.link}>
                      <img src="/images/github_icon.png" alt="github"/>
                    </Link>
                    <Link to={author.twitter} className={styles.link}>
                      <img src="/images/tw_icon.png" alt="twitter"/>
                    </Link>
                    <Link to={author.facebook} className={styles.link}>
                      <img src="/images/fb_icon.png" alt="Facebook"/>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className={styles.bio}
                dangerouslySetInnerHTML={{
                  __html:　author.shortBio.childMarkdownRemark.html,
                }}
              />
            </div>
            <Sidebar />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Author